import { useEffect, useMemo, useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Typography, IconButton, Button, Drawer, Select, Option } from '@material-tailwind/react';
import { Cog8ToothIcon, ArrowLeftOnRectangleIcon, UserIcon } from '@heroicons/react/24/solid';
import { useAuth } from 'src/context/AuthContext';
import { apiUrl } from 'src/constants';

export const Settings = () => {
	const [open, setOpen] = useState(false);

	const { t, i18n } = useTranslation();
	const { language: currentLanguage } = i18n;
	const { user, csrfToken, isAuthenticated, partner, customer, signOutMutation, dispatch } = useAuth();
	const navigate = useNavigate();

	const languageNames = useMemo(() => {
		return new Intl.DisplayNames([currentLanguage], { type: 'language' });
	}, [currentLanguage]);

	const languageList = Object.keys(i18n?.options?.resources ?? []);

	const capitalize = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const handleChangeLanguage = (value: string | undefined) => {
		localStorage.setItem('selected_language', value ?? '');
		i18n.changeLanguage(value);
		setOpen(false);
	};

	const handleChangeCustomer = (value: string | undefined) => {
		dispatch({ type: 'SET_CUSTOMER', payload: value ?? '' });
	};

	useEffect(() => {
		const languagePicked = localStorage.getItem('selected_language');

		if (languagePicked) {
			i18n.changeLanguage(languagePicked);
		} else if (partner?.language_code) {
			i18n.changeLanguage(partner.language_code);
		}
	}, [partner]);

	return (
		<>
			<div className="fixed bottom-5 right-5 animate-slide-in-bottom duration-300">
				<IconButton
					size="md"
					ripple={false}
					className="rounded-sm bg-gradient-to-tr from-primary to-primary-600 shadow-md shadow-primary-900 md:shadow-primary-700 hover:shadow-md hover:shadow-primary-900 hover:scale-105"
					onClick={() => setOpen(true)}
				>
					<Cog8ToothIcon className="h-6 w-6" />
				</IconButton>
			</div>
			<Drawer
				placement="left"
				open={open}
				onClose={() => setOpen(false)}
				className="flex flex-col p-4 gap-2"
				overlayProps={{ className: 'fixed' }}
			>
				<div id="settings-header" className="flex items-center justify-between mb-4">
					{user?.email ? (
						<div className="flex gap-2 items-center">
							{user.picture ? (
								<Avatar className="h-6 w-6 translate-x-0.5" src={user.picture} alt="avatar" />
							) : (
								<i className="rounded-full h-9 w-9 bg-gradient-to-tr from-secondary to-secondary-600 flex items-center justify-center not-italic">
									<span className="text-md font-normal text-white">
										{user?.email?.charAt(0).toUpperCase()}
									</span>
								</i>
							)}
							<div className="flex flex-col">
								<Typography variant="small" className="font-semibold lowercase max-w-[200px] truncate">
									{user.email}
								</Typography>
								{user.customers.length > 0 && (
									<Typography variant="small" className="font-light max-w-[200px] truncate">
										{user.customers.find((item) => item.customer_number === customer)?.name}
									</Typography>
								)}
							</div>
						</div>
					) : (
						<Typography variant="h5" color="blue-gray">
							{t('settings')}
						</Typography>
					)}
					<IconButton
						variant="text"
						color="blue-gray"
						onClick={() => setOpen(false)}
						className="rounded-sm ml-auto"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="h-5 w-5"
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</IconButton>
				</div>
				<div id="settings-links">
					<Button
						variant="text"
						color="blue-gray"
						fullWidth
						className="flex items-center gap-2 p-2 rounded-sm"
						onClick={() => {
							setOpen(false);
							navigate('/profile');
						}}
					>
						<UserIcon className="h-5 w-5 text-primary" />
						<Typography variant="small" className="capitalize">
							{t('viewProfile')}
						</Typography>
					</Button>
				</div>
				<hr className="my-2 border-blue-gray-50" />
				<div id="settings-content" className="flex flex-col gap-2">
					<Select
						variant="standard"
						value={currentLanguage}
						label={t('label')}
						containerProps={{ className: 'my-2' }}
						menuProps={{ className: 'rounded-sm' }}
						onChange={(value) => handleChangeLanguage(value)}
						selected={(element) =>
							element &&
							cloneElement(element, {
								disabled: true,
								className: 'flex items-center opacity-100 px-0 gap-2 pointer-events-none',
							})
						}
					>
						{languageList.map((language) => (
							<Option key={language} value={language} className="flex items-center rounded-sm gap-2">
								{capitalize(languageNames.of(language) ?? language)}
							</Option>
						))}
					</Select>
					{user?.email && (
						<>
							<Select
								variant="standard"
								value={customer}
								label={t('customer')}
								containerProps={{ className: 'my-2' }}
								menuProps={{ className: 'rounded-sm' }}
								onChange={(value) => handleChangeCustomer(value)}
								selected={(element) =>
									element &&
									cloneElement(element, {
										disabled: true,
										className: 'flex items-center opacity-100 px-0 gap-2 pointer-events-none',
									})
								}
							>
								{user.customers.map((customer) => (
									<Option
										key={customer.customer_number}
										value={customer.customer_number}
										className="my-1 rounded-sm"
									>
										{customer.name}
									</Option>
								))}
							</Select>
						</>
					)}
				</div>
				{isAuthenticated && (
					<div className="flex flex-col mt-auto gap-4">
						<Button
							className="bg-gradient-to-tr from-primary to-primary-600 flex justify-center items-center gap-2 rounded-sm"
							onClick={() => signOutMutation.mutate(void 0, { onSuccess: () => setOpen(false) })}
						>
							<ArrowLeftOnRectangleIcon className="relative h-5 w-5"></ArrowLeftOnRectangleIcon>
							<span>{t('signOut')}</span>
						</Button>
					</div>
				)}
			</Drawer>
		</>
	);
};

export default Settings;
