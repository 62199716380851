import { Typography, Tabs, TabPanel, TabsHeader, Tab, TabsBody } from '@material-tailwind/react';
import { useState } from 'react';
import EidMobileForm from './EidMobileForm';
import EidAppForm from './EidAppForm';
import { useTranslation } from 'react-i18next';

export const EidForm = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const { t } = useTranslation();

	const data = [
		{
			label: t('mobileEid'),
			index: 0,
			header: (
				<Typography variant="h3" color="black" className="text-center font-bold mb-8">
					{t('mobileEidHeader')}
				</Typography>
			),
			description: <Typography className="text-center mb-12">{t('signInDescription')}</Typography>,
			element: <EidMobileForm active={selectedTab === 0} />,
		},
		{
			label: t('appEid'),
			index: 1,
			header: (
				<Typography variant="h3" color="black" className="text-center font-bold mb-8">
					{t('appEidHeader')}
				</Typography>
			),
			description: <Typography className="text-center mb-12">{t('signInDescription')}</Typography>,
			element: <EidAppForm active={selectedTab === 1} />,
		},
	];

	const handleClick = (index: number) => {
		const loginForm = document.querySelector('#loginForm') as HTMLFormElement;
		loginForm.reset();
		setSelectedTab(index);
	};

	return (
		<Tabs value={data[selectedTab].index} className="w-full">
			<TabsHeader
				className="rounded-sm border-b bg-transparent p-0"
				indicatorProps={{
					className: 'bg-transparent border-b-2 border-primary shadow-none rounded-sm transition-transform',
				}}
			>
				{data.map(({ label, index }) => (
					<Tab key={index} value={index} onClick={() => handleClick(index)} activeClassName="text-primary">
						{label}
					</Tab>
				))}
			</TabsHeader>
			<TabsBody
				className="mt-8 p-0"
				animate={{
					initial: { x: data[selectedTab].index === 0 ? -250 : 250, opacity: 0 },
					mount: { x: 0, opacity: 1 },
					unmount: { x: data[selectedTab].index === 0 ? -250 : 250, opacity: 0, transition: { duration: 0 } },
				}}
			>
				{data.map(({ index, header, description, element }) => (
					<TabPanel key={index} value={index} className="px-0">
						{header}
						<hr className="my-4 border-blue-gray-50" />
						{description}
						{element}
					</TabPanel>
				))}
			</TabsBody>
		</Tabs>
	);
};

export default EidForm;
