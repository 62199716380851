import { Card, CardBody, CardHeader, Avatar, Typography } from '@material-tailwind/react';
import { useAuth } from 'src/context/AuthContext';
import { Accounts, CustomerInfo } from 'src/components';

interface ProfilePageProps {}

export const ProfilePage = (props: ProfilePageProps) => {
	const { user, customer } = useAuth();

	const customerInfo = user.customers.find((item) => item.customer_number === customer);

	return (
		<section className="h-screen">
			<Card className="md:p-4">
				<CardBody>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col md:flex-row items-center gap-6">
							{user.picture ? (
								<Avatar className="h-16 w-16" src={user.picture} alt="avatar" />
							) : (
								<i className="rounded h-16 w-16 bg-gradient-to-tr from-secondary to-secondary-600 flex items-center justify-center not-italic">
									<span className="text-md font-normal text-white">
										{user?.email?.charAt(0).toUpperCase()}
									</span>
								</i>
							)}
							<div>
								<Typography variant="h5" color="blue-gray" className="mb-1">
									{user?.email}
								</Typography>
								<Typography variant="small" className="font-normal text-blue-gray-600">
									{customerInfo?.name}
								</Typography>
							</div>
						</div>
						<hr className="my-2 border-blue-gray-50" />
						{customerInfo && <CustomerInfo customerData={customerInfo} />}
						<hr className="my-2 border-blue-gray-50" />
						<Accounts />
					</div>
				</CardBody>
			</Card>
		</section>
	);
};

export default ProfilePage;
