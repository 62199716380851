export const MaterialTailwindTheme = {
	iconButton: {
		defaultProps: {
			color: 'primary',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			variants: {
				filled: {
					primary: {
						background: 'bg-primary-500',
						color: 'text-white',
						shadow: 'shadow-md shadow-primary-500/20',
						hover: 'hover:shadow-lg hover:shadow-primary-500/40',
						focus: 'focus:opacity-[0.85] focus:shadow-none',
						active: 'active:opacity-[0.85] active:shadow-none',
					},
					secondary: {
						background: 'bg-secondary-500',
						color: 'text-white',
						shadow: 'shadow-md shadow-secondary-500/20',
						hover: 'hover:shadow-lg hover:shadow-secondary-500/40',
						focus: 'focus:opacity-[0.85] focus:shadow-none',
						active: 'active:opacity-[0.85] active:shadow-none',
					},
				},
				gradient: {
					primary: {
						background: 'bg-gradient-to-tr from-primary-600 to-primary',
						color: 'text-white',
						shadow: 'shadow-md shadow-primary-500/20',
						hover: 'hover:shadow-lg hover:shadow-primary-500/40',
						active: 'active:opacity-[0.85]',
					},
					secondary: {
						background: 'bg-gradient-to-tr from-secondary-600 to-secondary',
						color: 'text-white',
						shadow: 'shadow-md shadow-secondary-500/20',
						hover: 'hover:shadow-lg hover:shadow-secondary-500/40',
						active: 'active:opacity-[0.85]',
					},
				},
				outlined: {
					primary: {
						border: 'border border-primary-500',
						color: 'text-primary-500',
						hover: 'hover:opacity-75',
						focus: 'focus:ring focus:ring-primary-200',
						active: 'active:opacity-[0.85]',
					},
					secondary: {
						border: 'border border-secondary-500',
						color: 'text-secondary-500',
						hover: 'hover:opacity-75',
						focus: 'focus:ring focus:ring-secondary-200',
						active: 'active:opacity-[0.85]',
					},
				},
				text: {
					primary: {
						color: 'text-primary-500',
						hover: 'hover:bg-primary-500/10',
						active: 'active:bg-primary-500/30',
					},
					secondary: {
						color: 'text-secondary-500',
						hover: 'hover:bg-secondary-500/10',
						active: 'active:bg-secondary-500/30',
					},
				},
			},
		},
	},
	button: {
		defaultProps: {
			color: 'primary',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			variants: {
				filled: {
					primary: {
						background: 'bg-primary-500',
						color: 'text-white',
						shadow: 'shadow-md shadow-primary-500/20',
						hover: 'hover:shadow-lg hover:shadow-primary-500/40',
						focus: 'focus:opacity-[0.85] focus:shadow-none',
						active: 'active:opacity-[0.85] active:shadow-none',
					},
					secondary: {
						background: 'bg-secondary-500',
						color: 'text-white',
						shadow: 'shadow-md shadow-secondary-500/20',
						hover: 'hover:shadow-lg hover:shadow-secondary-500/40',
						focus: 'focus:opacity-[0.85] focus:shadow-none',
						active: 'active:opacity-[0.85] active:shadow-none',
					},
				},
				gradient: {
					primary: {
						background: 'bg-gradient-to-tr from-primary-600 to-primary',
						color: 'text-white',
						shadow: 'shadow-md shadow-primary-500/20',
						hover: 'hover:shadow-lg hover:shadow-primary-500/40',
						active: 'active:opacity-[0.85]',
					},
					secondary: {
						background: 'bg-gradient-to-tr from-secondary-600 to-secondary',
						color: 'text-white',
						shadow: 'shadow-md shadow-secondary-500/20',
						hover: 'hover:shadow-lg hover:shadow-secondary-500/40',
						active: 'active:opacity-[0.85]',
					},
				},
				outlined: {
					primary: {
						border: 'border border-primary-500',
						color: 'text-primary-500',
						hover: 'hover:bg-primary-500 hover:opacity-100 hover:text-white hover:transition hover:duration-300 hover:ease-in-out',
						focus: 'focus:ring focus:ring-primary-500',
						active: 'active:opacity-[0.85]',
					},
					secondary: {
						border: 'border border-secondary-500',
						color: 'text-secondary-500',
						hover: 'hover:opacity-75',
						focus: 'focus:ring focus:ring-secondary-200',
						active: 'active:opacity-[0.85]',
					},
				},
				text: {
					primary: {
						color: 'text-primary-500',
						hover: 'hover:bg-primary-500/10',
						active: 'active:bg-primary-500/30',
					},
					secondary: {
						color: 'text-secondary-500',
						hover: 'hover:bg-secondary-500/10',
						active: 'active:bg-secondary-500/30',
					},
				},
			},
		},
	},
	navbar: {
		defaultProps: {
			color: 'white',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			variants: {
				filled: {
					primary: {
						background: 'bg-primary-500',
						color: 'text-white',
					},
					secondary: {
						background: 'bg-secondary-500',
						color: 'text-white',
					},
				},
				gradient: {
					primary: {
						background: 'bg-gradient-to-tr from-primary-600 to-primary',
						color: 'text-white',
					},
					secondary: {
						background: 'bg-gradient-to-tr from-secondary-600 to-secondary',
						color: 'text-white',
					},
				},
			},
		},
	},
	card: {
		defaultProps: {
			color: 'white',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			variants: {
				filled: {
					primary: {
						background: 'bg-primary-500',
						color: 'text-white',
					},
					secondary: {
						background: 'bg-secondary-500',
						color: 'text-white',
					},
				},
				gradient: {
					primary: {
						background: 'bg-gradient-to-tr from-primary-600 to-primary',
						color: 'text-white',
					},
					secondary: {
						background: 'bg-gradient-to-tr from-secondary-600 to-secondary',
						color: 'text-white',
					},
				},
			},
		},
	},
	select: {
		defaultProps: {
			color: 'primary',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			base: {
				option: {
					initial: {
						background: 'hover:bg-primary-500 focus:bg-primary-500',
						opacity: 'hover:bg-opacity-100 focus:bg-opacity-100',
						color: 'hover:text-white focus:text-white',
					},
					active: {
						bg: 'bg-transparent',
					},
				},
			},
			variants: {
				standard: {
					colors: {
						select: {
							primary: {
								close: {
									borderColor: 'border-primary-200',
								},
								open: {
									borderColor: 'border-primary-500',
								},
								withValue: {
									borderColor: 'border-primary-200',
								},
							},
							secondary: {
								close: {
									borderColor: 'border-secondary-200',
								},
								open: {
									borderColor: 'border-secondary-500',
								},
								withValue: {
									borderColor: 'border-secondary-200',
								},
							},
						},
						label: {
							primary: {
								close: {
									color: 'text-primary-500',
									after: 'after:border-primary-500',
								},
								open: {
									color: 'text-primary-500',
									after: 'after:border-primary-500',
								},
								withValue: {
									color: 'text-primary-500',
									after: 'after:border-primary-100',
								},
							},
							secondary: {
								close: {
									color: 'text-secondary-500',
									after: 'after:border-secondary-500',
								},
								open: {
									color: 'text-secondary-500',
									after: 'after:border-secondary-500',
								},
								withValue: {
									color: 'text-secondary-500',
									after: 'after:border-secondary-100',
								},
							},
						},
					},
				},
			},
		},
	},
	tab: {
		defaultProps: {
			activeClassName: 'text-white',
		},
		styles: {
			base: {
				indicator: {
					bg: 'bg-primary-500',
				},
			},
		},
	},
	spinner: {
		defaultProps: {
			color: 'primary',
		},
		valid: {
			colors: ['primary', 'secondary'],
		},
		styles: {
			colors: {
				primary: {
					color: 'text-primary-500',
				},
				secondary: {
					color: 'text-secondary-500',
				},
			},
		},
	},
};

export default MaterialTailwindTheme;
