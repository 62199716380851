import { Button, IconButton } from '@material-tailwind/react';
import {
	ArrowRightIcon,
	ArrowLeftIcon,
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

type Size = 'sm' | 'md' | 'lg';

type Pagination = {
	total_pages: number;
	is_first_page: boolean;
	is_last_page: boolean;
	count: number;
};

interface PaginationProps {
	activePage: number;
	pagination: Pagination;
	onPageChange: (page: number) => void;
	disabled: boolean;
	size?: Size;
}

export const Pagination = (props: PaginationProps) => {
	const { activePage, pagination, onPageChange, disabled, size } = props;
	const { t } = useTranslation();

	const modifier = size === 'lg' ? 5 : size === 'sm' ? 1 : 3;
	const offset = size === 'lg' ? 4 : size === 'sm' ? 2 : 3;

	const getItemProps = (index: number) =>
		({
			variant: activePage === index ? 'filled' : 'text',
			ripple: false,
			className: 'rounded-sm transition duration-0 delay-0 active:!opacity-100 focus:!opacity-100',
			disabled: disabled ?? false,
			onClick: () => onPageChange(index),
		}) as any;

	const next = () => {
		if (pagination.is_last_page) return;

		onPageChange(activePage + 1);
	};

	const prev = () => {
		if (props.pagination.is_first_page) return;

		onPageChange(activePage - 1);
	};

	const getPagination = () => {
		if (!pagination) return;

		const pages = [];

		// Pagination offset
		// sm: activePage - 2 | md: activePage - 3 | lg: activePage - 4
		let startIndex = Math.max(activePage - offset, 0);

		// Pagination offset when at end of pagination list
		// sm: total_pages - 1 | md: total_pages - 3 | lg: total_pages - 5
		if (activePage > pagination.total_pages - modifier) {
			// sm: total_pages - 3 | md: total_pages - 5 | lg: total_pages - 7
			startIndex = Math.max(pagination.total_pages - (modifier + 2), 0);
		}

		// Number of pagination elements to display at a time
		// sm: startIndex + 3 | md: startIndex + 5 | lg: startIndex + 7
		let endIndex = Math.min(startIndex + (modifier + 2), pagination.total_pages);

		for (let i = startIndex; i < endIndex; i++) {
			pages.push(
				<IconButton key={i} {...getItemProps(i + 1)}>
					{i + 1}
				</IconButton>
			);
		}

		return pages;
	};

	return (
		<div className="flex justify-center items-center gap-2 mx-auto md:max-w-full">
			{pagination.total_pages > 5 && (
				<Button
					variant="text"
					ripple={false}
					onClick={() => onPageChange(1)}
					disabled={pagination.is_first_page || disabled}
					className="flex items-center gap-0 px-2 lg:px-4 active:bg-transparent focus:bg-transparent hover:bg-transparent"
				>
					<ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
				</Button>
			)}
			<Button
				variant="text"
				ripple={false}
				className="flex items-center gap-2 px-2 lg:px-4 active:bg-transparent focus:bg-transparent hover:bg-transparent"
				onClick={prev}
				disabled={pagination.is_first_page || disabled}
			>
				<ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
				{size !== 'sm' && t('previous')}
			</Button>
			<div className="flex items-center gap-2">{getPagination()}</div>
			<Button
				variant="text"
				ripple={false}
				className="flex items-center gap-2 px-2 lg:px-4 active:bg-transparent focus:bg-transparent hover:bg-transparent"
				onClick={next}
				disabled={pagination.is_last_page || disabled}
			>
				{size !== 'sm' && t('next')}
				<ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
			</Button>
			{pagination.total_pages > 5 && (
				<Button
					variant="text"
					ripple={false}
					onClick={() => onPageChange(pagination.total_pages)}
					disabled={pagination.is_last_page || disabled}
					className="flex items-center gap-0 px-2 lg:px-4 active:bg-transparent focus:bg-transparent hover:bg-transparent"
				>
					<ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
				</Button>
			)}
		</div>
	);
};

export default Pagination;
