import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		resources: {
			en: {
				translation: {
					invoice_id: 'Invoice ID',
					date: 'Date',
					search: 'Search',
					poweredBy: 'Powered by',
					customer: 'Customer',
					label: 'Language',
					incomingDocuments: 'Incoming Documents',
					outgoingDocuments: 'Outgoing Documents',
					settings: 'Settings',
					signOut: 'Sign Out',
					sender: 'Sender',
					receiver: 'Receiver',
					status: 'Status',
					type: 'Type',
					all: 'All',
					unsent: 'Unsent',
					new: 'New',
					fetched: 'Fetched',
					're-sent': 'Re-sent',
					done: 'Done',
					error: 'Error',
					deleted: 'Deleted',
					processing: 'Processing',
					invalid: 'Invalid',
					notfound: 'Not Found',
					emailBounced: 'Email Bounced',
					emailSent: 'Email Sent',
					emailUnknown: 'No Email registered',
					waitingForPickup: 'Waiting for Pickup',
					unknown: 'Unknown',
					incomingHeader: 'See messages you have received',
					outgoingHeader: 'See sent documents',
					next: 'Next',
					previous: 'Previous',
					sentPast30days: 'Sent Documents in the last 30 days',
					receivedPast30days: 'Received Documents in the last 30 days',
					viewedDocuments: 'Documents have been viewed in the last 30 days',
					uniqueIdentifiers: 'Unique Identifiers',
					fetchingData: 'Fetching new data...',
					loadingMessage: 'Please wait a moment...',
					mobileEid: 'Mobile Electronic ID',
					appEid: 'App Electronic ID',
					signIn: 'Sign in',
					mobileEidHeader: 'Sign in with Mobile Electronic ID',
					appEidHeader: 'Sign in with App Electronic ID',
					emailSignInDescription:
						'E-mail signup is only done by invitation. We recommend using Microsoft or eID login.',
					signInDescription: 'Our platform allows you to manage your e-Related invoices.',
					eidSignInLabel: 'Sign in with electronic ID',
					emailSignInLabel: 'Sign in with Email',
					microsoftSignInLabel: 'Sign in with microsoft',
					nationalId: 'National ID',
					phoneNumber: 'Phone Number',
					email: 'Email',
					password: 'Password',
					signInError: 'Error signing in',
					akreg_401: 'User is not allowed to create certificate',
					audkenni_400: 'User does not have a mobile phone number',
					audkenni_404: 'User not found',
					InvalidClientId: 'AP unknown',
					mssp_100: 'Got accepted state when checking the status. This should not happen',
					mssp_101: 'Wrong parameter',
					mssp_102: 'Missing parameter',
					mssp_103: 'Wrong data length',
					mssp_104: 'AP unknown',
					mssp_105: 'User not found',
					mssp_107: 'Unable to handle given data',
					mssp_108: 'Incompatible interface version.',
					mssp_109: 'Unsupported profile',
					mssp_208: 'Timeout - Ekkert svar frá síma',
					mssp_209: 'Timeout - Næst ekki í síma',
					mssp_401: 'User cancel',
					mssp_402: 'PIN blocked',
					mssp_403: 'Card blocked',
					mssp_404: 'No key found',
					mssp_422: 'No certificate found',
					mssp_425: 'Error in certificate validation',
					mssp_501: 'Certificate is revoked',
					mssp_503: 'Error in signature verfication',
					mssp_504: 'Request in progress',
					mssp_900: 'Error',
					SmartID_467: 'User cancel',
					SmartID_468: 'Timeout',
					SmartID_469: 'Communication error',
					SmartID_470: 'Wrong verification code selected',
					SmartID_471: 'User not found',
					SmartID_472: 'Error - Check Audkennis APP',
					SmartID_480: 'Incompatible interface version.',
					SmartID_580: 'System is under maintenance, retry later',
					SmartID_900: 'Error',
					auth_code_error: 'Error fetching authentication code',
					1: 'User cancel',
					404: 'User not found',
					408: 'Timeout',
					501: 'Invalid certificate',
					email_taken:
						'An account already exists with this email address. Please sign in to that account first, then connect your Microsoft account.',
					invalid_domain: 'Email does not match an active customer domain.',
					noCustomersTitle: 'No linked customers',
					noCustomersDescription: 'Sorry, this user account has no linked customers.',
					noCustomersMessage: 'Please contact your administrator to link customers to your account.',
					genericErrorTitle: 'Error',
					genericErrorDescription: 'An error occurred while processing your request.',
					genericErrorMessage: 'Please try again later.',
					customerInfo: 'Customer Information',
					accounts: 'Accounts',
					accountsDescription: 'Setup and manage your social accounts',
					enabled: 'Enabled',
					msAccountConnected: 'You have connected a Microsoft account.',
					msAccountNotConnected:
						'You have not connected a Microsoft account yet. Please add a Microsoft connection to access your company portal.',
					connectedAccount: 'Connected Account',
					connect: 'Connect',
					disconnect: 'Disconnect',
					viewProfile: 'View Profile',
					close: 'Close',
					resend: 'Resend',
					download: 'Download Files',
					downloadOriginal: 'Download Original Files',
					preview: 'Preview',
				},
			},
			is: {
				translation: {
					invoice_id: 'Reikningsnr',
					date: 'Dags.',
					search: 'Leita',
					poweredBy: 'Knúið af',
					customer: 'Viðskiptavinur',
					label: 'Tungumál',
					incomingDocuments: 'Innkomandi reikningar',
					outgoingDocuments: 'Send skjöl',
					settings: 'Stillingar',
					signOut: 'Útskrá',
					sender: 'Sendandi',
					receiver: 'Móttakandi',
					status: 'Staða',
					type: 'Týpa',
					all: 'Öll',
					unsent: 'Ósend',
					new: 'Nýtt',
					fetched: 'Sótt',
					're-sent': 'Endursent',
					done: 'Lokið',
					error: 'Villa',
					deleted: 'Eytt',
					processing: 'Í vinnslu',
					invalid: 'Ógilt',
					notfound: 'Fannst ekki',
					emailBounced: 'Tölvupósti hafnað',
					emailSent: 'Tölvupóstur sendur',
					emailUnknown: 'Ekkert netfang',
					waitingForPickup: 'Í bið',
					unknown: 'Óþekkt',
					incomingHeader: 'Sjá skeyti sem þú hefur fengið',
					outgoingHeader: 'Sjá send skjöl',
					next: 'Næsta',
					previous: 'Fyrri',
					sentPast30days: 'Send skjöl seinustu 30 daga',
					receivedPast30days: 'Innkomandi reikningar seinustu 30 daga',
					viewedDocuments: 'Reikningar hafa verið skoðaðir seinustu 30 daga',
					uniqueIdentifiers: 'Einstök auðkenni',
					fetchingData: 'Sæki ný gögn...',
					loadingMessage: 'Vinsamlegast hinkraðu í augnablik...',
					mobileEid: 'Skilríki í síma',
					appEid: 'Skilríki í appi',
					signIn: 'Skrá inn',
					mobileEidHeader: 'Innskráning með skilríki í síma',
					appEidHeader: 'Innskráning með skilríki í appi',
					emailSignInDescription:
						'Skráning með netfangi er aðeins gerð með boði. Við mælum frekar með Microsoft innskráningu eða rafrænu skilríki.',
					signInDescription: 'Okkar vefur leyfir þér að sjá um þína rafrænu reikninga.',
					eidSignInLabel: 'Innskráning með rafrænu skilríki',
					emailSignInLabel: 'Innskráning með netfangi',
					microsoftSignInLabel: 'Innskráning með Microsoft',
					nationalId: 'Kennitala',
					phoneNumber: 'Símanúmer',
					email: 'Netfang',
					password: 'Lykilorð',
					signInError: 'Innskráning mistókst',
					akreg_401: 'Notandi má ekki framleiða skilríki með núverandi skilríki',
					audkenni_400: 'Notandi er ekki með símanúmer',
					audkenni_404: 'Notandi finnst ekki',
					InvalidClientId: 'Þjónustuveitandi ekki þekktur',
					mssp_100: '',
					mssp_101: 'Beiðni ekki rétt',
					mssp_102: 'Vantar breytu',
					mssp_103: 'Svæði í beiðni of stórt',
					mssp_104: 'Þjónustuveitandi ekki þekktur',
					mssp_105: 'Notandi finnst ekki.',
					mssp_107: 'Ekki hægt að vinna úr beiðni',
					mssp_108: 'Misræmi í útgáfum',
					mssp_109: 'Óþekktur prófíll',
					mssp_208: 'Rann út á tíma',
					mssp_209: 'Rann út á tíma',
					mssp_401: 'Notandi hætti við',
					mssp_402: 'PIN læst',
					mssp_403: 'Kort læst',
					mssp_404: 'Skilríki finnast ekki',
					mssp_422: 'Skilríki finnast ekki',
					mssp_425: 'Villa við sannreyningu',
					mssp_501: 'Skilríki er afturkallað',
					mssp_503: 'Villa við sannreyningu',
					mssp_504: 'Beiðni í vinnslu',
					mssp_900: 'Villa í innri kerfum',
					SmartID_467: 'Notandi hætti við',
					SmartID_468: 'Rann út á tíma',
					SmartID_469: 'Villa í samskiptum',
					SmartID_470: 'Rangur staðfestingarkóði valinn',
					SmartID_471: 'Notandi finnst ekki',
					SmartID_472: 'Villa - athugið Auðkennis APP',
					SmartID_480: 'Misræmi í útgáfum',
					SmartID_580: 'Viðhaldsvinna í gangi, reynið aftur síðar',
					SmartID_900: 'Villa í innri kerfum',
					auth_code_error: 'Villa að sækja auðkenningarkóða',
					1: 'Notandi hætti við',
					404: 'Notandi finnst ekki',
					408: 'Rann út á tíma',
					501: '',
					email_taken:
						'Aðgangur nú þegar til með þessu netfangi. Vinsamlegast skráðu þig inn á þennan aðgang fyrst og tengdu þig síðan við Microsoft aðganginn.',
					invalid_domain: 'Netfang passar ekki við virkan viðskiptavin.',
					noCustomersTitle: 'Vantar tengingu við viðskiptavin',
					noCustomersDescription: 'Því miður hefur þessi notandi enga viðskiptavini tengda við sig.',
					noCustomersMessage:
						'Vinsamlegast hafðu samband við kerfisstjóra til að bæta tengingu við aðganginn þinn.',
					genericErrorTitle: 'Villa',
					genericErrorDescription: 'Villa kom upp við að vinna beiðni þína.',
					genericErrorMessage: 'Vinsamlegast reynið aftur síðar.',
					customerInfo: 'Upplýsingar um viðskiptavin',
					accounts: 'Aðgangar',
					accountsDescription: 'Stillingar fyrir tengda aðganga',
					enabled: 'Virkjað',
					msAccountConnected: 'Þú hefur tengt Microsoft aðgang.',
					msAccountNotConnected:
						'Þú hefur ekki tengt Microsoft aðgang. Vinsamlegast bættu við tengingu til að fá aðgang að fyrirtækja síðunni.',
					connectedAccount: 'Tengdur aðgangur',
					connect: 'Tengja',
					disconnect: 'Aftengja',
					viewProfile: 'Skoða prófíl',
					close: 'Loka',
					resend: 'Endursenda',
					download: 'Sækja skjöl',
					downloadOriginal: 'Sækja upprunaleg skjöl',
					preview: 'Forskoða',
				},
			},
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
