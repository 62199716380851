import { Typography, Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import background from 'src/images/pattern-lines.png';
import { useAuth } from 'src/context/AuthContext';
import { useEffect } from 'react';

interface ErrorPageProps {
	title?: string;
	description?: string;
	message?: string;
}

export const ErrorPage = (props: ErrorPageProps) => {
	const { signOutMutation, isAuthenticated } = useAuth();
	const { t } = useTranslation();

	const { title, description, message } = props;

	useEffect(() => {
		if (signOutMutation.isSuccess) {
			window.location.href = '/';
		}
	}, [signOutMutation.isSuccess]);

	return (
		<section>
			<div className="relative min-h-screen w-full bg-[url('src/images/pattern-lines.png')] bg-cover bg-no-repeat">
				<div className="grid min-h-screen px-8">
					<div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
						<Typography variant="h1" color="blue-gray" className="text-4xl !leading-snug lg:text-5xl">
							{title ?? t('genericErrorTitle')}
						</Typography>
						<Typography variant="h1" color="blue-gray" className="mt-6 text-4xl !leading-snug lg:text-3xl">
							{description ?? t('genericErrorDescription')}
						</Typography>
						<Typography
							variant="lead"
							color="gray"
							className="mt-4 mb-6 w-full md:max-w-full lg:mb-12 lg:max-w-3xl"
						>
							{message ?? t('genericErrorMessage')}
						</Typography>
						{isAuthenticated && (
							<div className="flex items-center gap-x-10 ">
								<Button className="bg-transparent text-primary border-2 border-primary hover:bg-primary hover:text-white hover:border-transparent hover:bg-opacity-100 active:bg-primary active:text-white active:border-transparent active:bg-opacity-100 focus:bg-primary focus:text-white focus:border-transparent focus:bg-opacity-100">
									<a
										className="flex flex-row items-center justify-center"
										onClick={() => signOutMutation.mutate()}
									>
										<ArrowLeftOnRectangleIcon className="relative h-5 w-5"></ArrowLeftOnRectangleIcon>
										<span>{t('signOut')}</span>
									</a>
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ErrorPage;
