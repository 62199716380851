import React, { useEffect, useState } from 'react';
import { Navbar, Typography, IconButton, Collapse } from '@material-tailwind/react';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as WiseLogo } from 'src/images/logo.svg';
import CustomButton from 'src/components/CustomButton';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';
import ProfileMenu from 'src/components/ProfileMenu';

interface Route {
	icon: JSX.Element;
	name: string;
	path: string;
	element: JSX.Element;
}
interface Routes {
	home: Route;
	pages: Route[];
}

interface NavbarProps {
	routes: Routes;
}

export function StickyNavbar({ routes }: NavbarProps) {
	const [openNav, setOpenNav] = useState(false);
	const { user, partner } = useAuth();
	const { t } = useTranslation();

	useEffect(() => {
		window.addEventListener('resize', () => window.innerWidth >= 960 && setOpenNav(false));
	}, []);

	const navList = (
		<ul className="mt-4 pt-4 mb-4 border-t-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 lg:border-0 lg:pt-0">
			{routes.pages.map((route, i) => (
				<NavLink to={route.path} key={i}>
					{({ isActive }) => (
						<CustomButton
							key={i}
							variant={isActive ? 'gradient' : 'text'}
							size="sm"
							color={isActive ? 'primary' : undefined}
							className="flex items-center gap-4 px-4 capitalize w-full rounded-sm"
						>
							{route.icon}
							<Typography
								key={i}
								as="li"
								variant="small"
								color={isActive ? 'white' : 'blue-gray'}
								className="flex items-center gap-x-2 p-1 font-semibold"
							>
								{t(route.name)}
							</Typography>
						</CustomButton>
					)}
				</NavLink>
			))}
		</ul>
	);

	return (
		<>
			<Navbar
				variant="gradient"
				className="sticky top-0 z-10 h-max max-w-full rounded-sm px-4 py-2 lg:pl-8 lg:pr-2 lg:py-4 border-0"
			>
				<div className="flex items-center justify-between text-blue-gray-900">
					<div className="flex items-center pt-0 h-[40px] w-auto gap-2 justify-start lg:hidden">
						<IconButton
							variant="text"
							className="absolute mr-1 ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent items-start"
							ripple={false}
							onClick={() => setOpenNav(!openNav)}
						>
							{openNav ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									className="h-6 w-6"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
								</svg>
							)}
						</IconButton>
					</div>
					<div className="flex items-center pt-0 h-[40px] w-auto gap-2 justify-start">
						<div className="flex h-auto w-24 items-center">
							<Link to={routes.home.path}>
								{partner.theme?.logo ? (
									<img
										style={{ maxHeight: '40px' }}
										src={`data:image/svg+xml;utf8, ${encodeURIComponent(partner.theme.logo)}`}
									></img>
								) : (
									<Typography variant="h3" className="font-bold text-primary">
										{partner?.name}
									</Typography>
								)}
							</Link>
						</div>
					</div>
					<div className="flex items-center justify-end gap-4">
						<div className="mr-4 hidden lg:block">{navList}</div>
					</div>
				</div>
				<Collapse open={openNav}>{navList}</Collapse>
			</Navbar>
		</>
	);
}
