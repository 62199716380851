import { Theme } from 'src/types/Partner';

const DefaultTheme = {
	name: 'Default Theme',
	logo: '<svg xmlns="http://www.w3.org/2000/svg" width="870" height="310" class="h-8 w-auto" style="width:100%;height:100%;transform:translate3d(0,0,0)"><defs><clipPath id="a"><path d="M0 0h870v310H0z"/></clipPath></defs><g clip-path="url(#a)" style="display:block"><path d="M298.675 163.096h41.126l17.886 86.644h2.035l25.597-86.644h38.556l25.597 86.644h2.035l17.885-86.644h41.127l-35.772 134.946h-43.482l-25.811-82.252h-1.5l-26.025 82.36h-43.483l-35.77-135.054zM531.725 163.097h39.627v134.946h-39.627V163.097zm-3.642-36.522c0-13.28 9.746-21.42 23.241-21.42s23.24 8.14 23.24 21.42c0 13.28-9.745 21.42-23.24 21.42s-23.24-8.14-23.24-21.42zM592.772 256.702h36.092c1.071 9.425 8.997 14.352 21.956 14.352 13.28 0 20.67-4.82 20.67-12.531 0-24.312-76.148 2.25-76.148-55.692 0-23.026 19.385-42.947 55.478-42.947 31.166 0 53.657 14.78 55.478 42.947h-34.272c-1.285-8.14-8.14-13.816-21.206-13.816-11.995 0-19.706 4.82-19.706 11.995 0 21.956 78.718-4.39 78.718 55.478 0 26.025-20.992 44.982-59.333 44.982-38.77-.107-56.87-17.457-57.727-44.768zM825.929 215.254c-1.5-14.351-13.28-25.275-30.952-25.275-16.922 0-29.131 11.995-30.952 25.275h61.904zM725.791 231.32c0-42.197 28.167-71.543 69.293-71.543 44.447 0 67.687 32.666 67.687 70.258v11.46h-99.388c1.285 17.136 13.816 29.345 32.451 29.345 15.637 0 26.025-7.711 29.88-16.065h35.772c-6.104 28.382-30.63 46.482-67.259 46.482-39.091.107-68.436-29.239-68.436-69.937z"/><path fill="#E4573D" d="M73.915 31.387c41.289-23.016 81.533-29.649 89.81-14.801 8.276 14.848-18.527 45.59-59.816 68.607-41.289 23.017-81.533 29.65-89.81 14.802-8.276-14.848 18.527-45.591 59.816-68.608zM134.313 130.33c.802-46.71 30.407-84.137 66.067-83.525 35.66.613 63.962 39.034 63.16 85.744-.803 46.71-30.408 84.136-66.068 83.524-35.66-.613-63.962-39.034-63.16-85.744zM11.85 135.091c13.144-15.563 53.314-3.287 89.645 27.398 36.332 30.684 55.158 68.233 42.013 83.797-13.144 15.563-53.315 3.287-89.646-27.398-36.331-30.684-55.157-68.233-42.013-83.797z"/></g></svg>',
	primary: {
		DEFAULT: 'rgb(229 88 62)',
		color_100: 'rgb(239 155 139)',
		color_200: 'rgb(237 138 120)',
		color_300: 'rgb(234 121 101)',
		color_400: 'rgb(232 105 81)',
		color_500: 'rgb(229 88 62)',
		color_600: 'rgb(206 79 56)',
		color_700: 'rgb(183 70 50)',
		color_800: 'rgb(160 62 43)',
		color_900: 'rgb(137 53 37)',
	},
	secondary: {
		DEFAULT: 'rgb(62 203 229)',
		color_100: 'rgb(139 224 239)',
		color_200: 'rgb(120 219 237)',
		color_300: 'rgb(101 213 234)',
		color_400: 'rgb(81 208 232)',
		color_500: 'rgb(62 203 229)',
		color_600: 'rgb(56 183 206)',
		color_700: 'rgb(50 162 183)',
		color_800: 'rgb(43 142 160)',
		color_900: 'rgb(37 122 137)',
	},
} as Theme;

export { DefaultTheme };
