import { useState, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody, Typography, Switch, Button } from '@material-tailwind/react';
import { useAuth } from 'src/context/AuthContext';
import { apiUrl } from 'src/constants';
import { useTranslation } from 'react-i18next';

export const Accounts = () => {
	const { user, csrfToken } = useAuth();
	const [msOpen, setMsOpen] = useState(false);

	const { t } = useTranslation();

	const microsoftConnect = () => {
		window.location.href = `${apiUrl}/internal_api/auth/microsoft/connect?next=${window.location.href}`;
	};

	const microsoftDisconnect = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/microsoft/disconnect`, {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrfToken,
			},
		});

		if (response.ok) {
			window.location.reload();
		}
	};

	const msAccount = user.social_accounts.find((item) => item.provider === 'microsoft');

	return (
		<div className="flex flex-col gap-2">
			<Typography variant="h6" color="blue-gray">
				{t('accounts')}
			</Typography>
			<Typography variant="small" className="font-normal text-blue-gray-500">
				{t('accountsDescription')}
			</Typography>
			<Accordion id="microsoft-account" open={msOpen}>
				<AccordionHeader
					color="blue-gray"
					onClick={() => setMsOpen(!msOpen)}
					className="flex flex-col sm:flex-row justify-start"
				>
					<div className="flex gap-2 items-center">
						<div className="border p-2.5 rounded-md">
							<div className="h-[20px] max-h-[20px]">
								<svg height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
									<path d="M0 0h15.206v15.206H0z" fill="#f25022" />
									<path d="M16.794 0H32v15.206H16.794z" fill="#7fba00" />
									<path d="M0 16.794h15.206V32H0z" fill="#00a4ef" />
									<path d="M16.794 16.794H32V32H16.794z" fill="#ffb900" />
								</svg>
							</div>
						</div>
						<Typography variant="h6" color="blue-gray">
							Microsoft
						</Typography>
					</div>
					<div className="sm:ml-auto">
						<Switch
							crossOrigin=""
							ripple={false}
							checked={!!msAccount}
							disabled={true}
							label={<Typography variant="paragraph">{t('enabled')}</Typography>}
						/>
					</div>
				</AccordionHeader>
				<AccordionBody className="flex flex-col gap-2">
					{msAccount ? (
						<Typography variant="small">{t('msAccountConnected')}</Typography>
					) : (
						<Typography variant="small">{t('msAccountNotConnected')}</Typography>
					)}
					<div className="w-full py-2 px-4 flex flex-col sm:flex-row justify-between bg-blue-gray-50 opacity-80 rounded-md items-center">
						<Typography variant="small" className="font-semibold">
							{t('connectedAccount')}
						</Typography>
						<div className="relative flex flex-col sm:flex-row items-center gap-2 h-full">
							{msAccount ? (
								<>
									<Typography variant="small">{msAccount.extra_data?.mail}</Typography>
									<Button
										variant="text"
										color="gray"
										className="p-2 rounded-sm"
										onClick={() => microsoftDisconnect()}
									>
										{t('disconnect')}
									</Button>
								</>
							) : (
								<Button
									variant="text"
									color="gray"
									className="p-2 rounded-sm"
									onClick={() => microsoftConnect()}
								>
									{t('connect')}
								</Button>
							)}
						</div>
					</div>
				</AccordionBody>
			</Accordion>
		</div>
	);
};

export default Accounts;
