import { Button } from '@material-tailwind/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { apiUrl } from 'src/constants';
import { useAuth } from 'src/context/AuthContext';

export const LoginMethods = ({ onBtnChange }: { onBtnChange: (value: string) => void }) => {
	const [buttonValue, setButtonValue] = useState('Email');
	const { t } = useTranslation();
	const { csrfToken } = useAuth();

	const handleClick = (): void => {
		const newValue = buttonValue === 'eID' ? 'Email' : 'eID';
		setButtonValue(newValue);
		onBtnChange(newValue);
	};

	const microsoftLogin = () => {
		window.location.href = `${apiUrl}/internal_api/auth/microsoft/login?next=${window.location.href}`;
	};

	return (
		<div className="w-full p-4">
			<div className="flex flex-col">
				<Button
					variant="text"
					color="gray"
					className="flex flex-row items-center justify-center gap-x-2 w-full rounded-sm"
					onClick={() => microsoftLogin()}
				>
					<div className="h-[20px] max-h-[20px]">
						<svg height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<path d="M0 0h15.206v15.206H0z" fill="#f25022" />
							<path d="M16.794 0H32v15.206H16.794z" fill="#7fba00" />
							<path d="M0 16.794h15.206V32H0z" fill="#00a4ef" />
							<path d="M16.794 16.794H32V32H16.794z" fill="#ffb900" />
						</svg>
					</div>
					{t('microsoftSignInLabel')}
				</Button>
				<Button
					variant="text"
					color="gray"
					className="flex flex-row items-center justify-center gap-x-2 w-full rounded-sm"
					onClick={handleClick}
				>
					{buttonValue === 'eID' ? (
						<>
							<DevicePhoneMobileIcon className="h-[20px]" />
							{t('eidSignInLabel')}
						</>
					) : (
						<>
							<EnvelopeIcon className="h-[20px]" />
							{t('emailSignInLabel')}
						</>
					)}
				</Button>
			</div>
		</div>
	);
};

export default LoginMethods;
