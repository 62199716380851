import { DocumentCheckIcon, ArrowPathRoundedSquareIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { Typography, IconButton, Tooltip, Chip } from '@material-tailwind/react';
import { useQueryClient, useMutation, keepPreviousData } from '@tanstack/react-query';

import { EnvelopeDto } from 'src/types/Envelope';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'src/constants';
import { b64DecodeUnicode } from 'src/util/decode';
import { FolderArrowDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface SubTableProps {
	items: EnvelopeDto[];
	count: number;
	queryKey: any;
	mutations: boolean;
}
interface FileObject {
	file: string;
	file_name: string;
	file_format: string;
	file_format_type: string;
}

export const SubTable = (props: SubTableProps) => {
	const queryClient = useQueryClient();

	const { customer, csrfToken, user } = useAuth();
	const { t, i18n } = useTranslation();

	const updateEnvelope = async ({ id, status }: { id: string; status: number }) => {
		const response = await fetch(`${apiUrl}/internal_api/portal/envelope/${id}`, {
			credentials: 'include',
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrfToken,
			},
			body: JSON.stringify({
				status: status,
			}),
		});

		return response.json();
	};

	const envelopeMutation = useMutation({
		mutationFn: updateEnvelope,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: props.queryKey }),
	});

	const handleDownload = async (id: string, format?: string) => {
		try {
			let url = `${apiUrl}/internal_api/portal/envelope/${id}/files?language_code=${i18n.language}`;

			if (format) {
				url += `&format=${format}`;
			}

			const response = await fetch(url, {
				credentials: 'include',
			});

			const responseJson = await response.json();

			responseJson.map((obj: FileObject) => {
				let downloadUrl = '';

				if (obj.file_format === 'pdf') {
					downloadUrl = `data:application/pdf;base64,${obj.file}`;
				} else {
					const blob = new Blob([b64DecodeUnicode(obj.file)], { type: obj.file_format_type });
					downloadUrl = window.URL.createObjectURL(blob);
				}
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = obj.file_name + '.' + obj.file_format;
				a.click();
			});
		} catch (error) {
			console.error(error);
		}
	};

	const handlePreview = (id: string) => {
		window.open(`${apiUrl}/einvoice/${id}/pdf/?language=${i18n.language}`, '_blank');
	};

	const getStatusColor = (status: number) => {
		switch (status) {
			case 0:
				return 'blue-gray';
			case 1:
				return 'blue';
			case 2:
				return 'orange';
			case 3:
				return 'green';
			case 4:
				return 'red';
			case 5:
				return 'gray';
			case 6:
				return 'yellow';
			case 7:
				return 'red';
			case 8:
				return 'indigo';
			case 9:
				return 'red';
			case 10:
				return 'cyan';
			case 11:
				return 'deep-orange';
			default:
				return 'blue-gray';
		}
	};

	const getStatusText = (status: number) => {
		switch (status) {
			case 0:
				return t('new');
			case 1:
				return t('fetched');
			case 2:
				return t('re-sent');
			case 3:
				return t('done');
			case 4:
				return t('error');
			case 5:
				return t('deleted');
			case 6:
				return t('processing');
			case 7:
				return t('invalid');
			case 8:
				return t('notfound');
			case 9:
				return t('emailBounced');
			case 10:
				return t('emailSent');
			case 11:
				return t('waitingForPickup');
			case 12:
				return t('emailUnknown');
			default:
				return t('unknown');
		}
	};

	return (
		<tbody>
			{props.items &&
				props.items.map((envelope, index) => {
					const isLast = index === props.items?.length - 1;
					const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

					return (
						<tr key={envelope.envelope_id}>
							<td className={classes}>
								<div className="flex items-center gap-3">
									<div className="flex flex-col">
										<Typography variant="small" color="blue-gray" className="font-normal">
											{envelope.sender}
										</Typography>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{envelope.invoice?.sender_name}
										</Typography>
									</div>
								</div>
							</td>
							<td className={classes}>
								<div className="flex flex-col">
								<Typography variant="small" color="blue-gray" className="font-normal">
											{envelope.receiver}
								</Typography>
								<Typography variant="small" color="blue-gray" className="font-normal">
										{envelope.invoice?.receiver_name}
								</Typography>
								</div>
							</td>
							<td className={classes}>
								<div className="flex flex-col">
									<Typography variant="small" color="blue-gray" className="font-normal">
										{envelope.invoice?.invoice_id}
									</Typography>
								</div>
							</td>
							<td className={classes}>
								<div className="flex flex-col">
									<Chip
										variant="gradient"
										color={getStatusColor(envelope.status)}
										value={getStatusText(envelope.status)}
										className="py-0.5 px-2 text-[11px] font-medium w-fit rounded-sm"
									/>
								</div>
							</td>
							<td className={classes}>
								<div className="flex flex-col">
									<Typography variant="small" color="blue-gray" className="font-normal">
										{envelope.sender_document_type}
									</Typography>
								</div>
							</td>
							<td className={classes}>
								<div className="flex flex-col">
									<Typography variant="small" color="blue-gray" className="font-normal">
										{new Date(envelope.created_at).toLocaleString('en-GB')}
									</Typography>
								</div>
							</td>
							<td className={classes}>
								<div className="flex items-center">
									{props.mutations && (
										<>
											{(envelope.status === 1 || envelope.status === 3) && (
												<Tooltip content={t('resend')}>
													<IconButton
														variant="text"
														className="rounded-sm"
														onClick={() =>
															envelopeMutation.mutate({
																id: envelope.envelope_id,
																status: 2,
															})
														}
													>
														<ArrowPathRoundedSquareIcon className="h-4 w-4" />
													</IconButton>
												</Tooltip>
											)}
											{envelope.status === 0 && (
												<Tooltip content={t('close')}>
													<IconButton
														variant="text"
														className="rounded-sm"
														onClick={() =>
															envelopeMutation.mutate({
																id: envelope.envelope_id,
																status: 3,
															})
														}
													>
														<DocumentCheckIcon className="h-4 w-4" />
													</IconButton>
												</Tooltip>
											)}
										</>
									)}
									<Tooltip content={t('download')}>
										<IconButton
											variant="text"
											className="rounded-sm"
											onClick={() => {
												handleDownload(envelope.envelope_id, 'pdf');
											}}
										>
											<ArrowDownTrayIcon className="h-4 w-4" />
										</IconButton>
									</Tooltip>
									{envelope.has_peppol && (
										<Tooltip content={t('preview')}>
											<IconButton
												variant="text"
												className="rounded-sm"
												onClick={() => {
													handlePreview(envelope.envelope_id);
												}}
											>
												<MagnifyingGlassIcon className="h-4 w-4" />
											</IconButton>
										</Tooltip>
									)}
									{user.is_staff && (
										<Tooltip content={t('downloadOriginal')}>
											<IconButton
												variant="text"
												className="rounded-sm"
												onClick={() => {
													handleDownload(envelope.envelope_id);
												}}
											>
												<FolderArrowDownIcon className="h-4 w-4" />
											</IconButton>
										</Tooltip>
									)}
								</div>
							</td>
						</tr>
					);
				})}
		</tbody>
	);
};

SubTable.defaultProps = {
	mutations: false,
};

export default SubTable;
