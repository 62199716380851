import { Button, ButtonProps } from '@material-tailwind/react';

interface Props extends Omit<ButtonProps, 'color'> {
	color?:
		| 'primary'
		| 'secondary'
		| 'blue-gray'
		| 'gray'
		| 'brown'
		| 'deep-orange'
		| 'orange'
		| 'amber'
		| 'yellow'
		| 'lime'
		| 'light-green'
		| 'green'
		| 'teal'
		| 'cyan'
		| 'light-blue'
		| 'blue'
		| 'indigo'
		| 'deep-purple'
		| 'purple'
		| 'pink'
		| 'red';
}

export default function CustomButton(props: Props) {
	return <Button {...(props as any)} />;
}
