import { EidForm, EmailForm } from 'src/components';
import { useState, useEffect } from 'react';
import LoginMethods from '../components/LoginMethods';
import { useAuth } from 'src/context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Button, Alert } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

export const LoginPage = () => {
	const [selectedBtn, setSelectedBtn] = useState(0);

	const { signInMutation, isAuthenticated, partner } = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();
	let [searchParams, setSearchParams] = useSearchParams();

	const handleBtnClick = (buttonLbl: string) => {
		const isEmail = buttonLbl === 'Email' ? 0 : 1;
		setSelectedBtn(isEmail);
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/');
		}
	}, [isAuthenticated]);

	const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = e.currentTarget;
		const formData = new FormData(form);
		const data = Object.fromEntries(formData.entries());

		signInMutation.mutate({ ...data, partner_name: partner.name });
	};

	const loginError = searchParams.get('error');

	return (
		<section className="h-screen max-w-md flex flex-col items-center justify-center m-auto p-2 gap-2">
			<div className="flex justify-center mb-8">
				{partner.theme?.logo ? (
					<img
						className="max-h-[60px] w-auto"
						src={`data:image/svg+xml;utf8, ${encodeURIComponent(partner.theme.logo)}`}
					></img>
				) : (
					<Typography variant="h2" className="font-bold text-primary" textGradient>
						{partner?.name}
					</Typography>
				)}
			</div>
			<form id="loginForm" className="p-8" onSubmit={(e) => handleOnSubmit(e)}>
				{selectedBtn === 0 ? <EidForm /> : <EmailForm />}
				{signInMutation.isError && (
					<Alert
						color="red"
						variant="outlined"
						className="mb-4"
						open={signInMutation.isError}
						onClose={() => signInMutation.reset()}
						animate={{
							mount: { y: 0 },
							unmount: { y: 100 },
						}}
					>
						{t(signInMutation.error?.message || 'signInError')}
					</Alert>
				)}
				{loginError && (
					<Alert
						color="red"
						variant="outlined"
						className="mb-4"
						open={!!loginError}
						onClose={() => setSearchParams()}
						animate={{
							mount: { y: 0 },
							unmount: { y: 100 },
						}}
					>
						{t(loginError)}
					</Alert>
				)}
				<div className="flex items-center justify-center">
					<Button
						type="submit"
						variant="gradient"
						className="mt-3 text-sm flex items-center justify-center w-full rounded-sm"
						loading={signInMutation.isPending}
					>
						{t('signIn')}
					</Button>
				</div>
			</form>
			<div className="my-2 flex w-full items-center gap-2">
				<hr className="w-full bg-blue-gray-50" />
				<Typography variant="small" color="blue-gray" className="font-medium opacity-50">
					OR
				</Typography>
				<hr className="w-full bg-blue-gray-50" />
			</div>
			<LoginMethods onBtnChange={handleBtnClick}></LoginMethods>
			<Typography color="gray" className="mt-6 text-center font-normal">
				{t('poweredBy') + ' ' + partner.name}
			</Typography>
		</section>
	);
};

export default LoginPage;
