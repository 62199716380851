import { Input } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { useAuth } from 'src/context/AuthContext';

export const EidMobileForm = ({ active }: { active: boolean }) => {
	const { t } = useTranslation();
	const { signInMutation } = useAuth();

	const label = t('phoneNumber');

	return (
		<div className="mb-4">
			<Input
				id="mobile_number"
				variant="standard"
				color="gray"
				size="lg"
				type="text"
				pattern="^\d+$"
				name="mobile_number"
				label={label}
				crossOrigin={''}
				disabled={!active}
				required={active}
				icon={<DevicePhoneMobileIcon />}
			/>
		</div>
	);
};

export default EidMobileForm;
