import { Routes, Route } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { apiUrl } from 'src/constants/index';
import { StickyNavbar as Navbar } from 'src/components/NavBar';
import { useAuth } from 'src/context/AuthContext';
import { CustomerIdentifier } from 'src/types/User';
import routes from 'src/routes';
import HttpResponseError from 'src/errors/HttpResponseError';

export const AuthRouter = () => {
	const { isAuthenticated, isLoading, user, customer, getUserInfo } = useAuth();

	useQuery({
		queryKey: ['user'],
		queryFn: getUserInfo,
		retry: false,
		enabled: !!isAuthenticated && !user.email,
		throwOnError: true,
	});

	const fetchCustomerIdentifiers = async (): Promise<CustomerIdentifier[]> => {
		const response = await fetch(`${apiUrl}/internal_api/portal/customer/identifiers`, {
			credentials: 'include',
		});

		if (!response.ok) {
			throw new HttpResponseError('Error fetching customer identifiers', response);
		}

		return response.json();
	};

	const { data: customerIdentifiers, isPending } = useQuery({
		queryKey: ['customerIdentifiers', customer],
		queryFn: fetchCustomerIdentifiers,
		enabled: !!customer,
		staleTime: Infinity,
		throwOnError: true,
	});

	if (isPending) {
		return null;
	}

	return (
		<>
			<Navbar routes={routes} />
			<div className="relative min-h-[calc(90dvh)] w-full">
				<div className="container mx-auto py-4 lg:py-12">
					<Routes>
						<Route path="/" element={routes.home.element} />
						{routes.pages.map((route, i) => (
							<Route key={i} path={route.path} element={route.element} />
						))}
						<Route path={routes.profile.path} element={routes.profile.element} />
					</Routes>
				</div>
			</div>
		</>
	);
};

export default AuthRouter;
