import { Input, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import { useAuth } from 'src/context/AuthContext';

export const EidAppForm = ({ active }: { active: boolean }) => {
	const { t } = useTranslation();
	const { signInMutation } = useAuth();

	const label = t('nationalId');

	return (
		<div className="mb-4">
			<Input
				id="national_id"
				variant="standard"
				color="gray"
				size="lg"
				type="text"
				pattern="^(\d{10})*$"
				name="national_id"
				label={label}
				crossOrigin={''}
				disabled={!active}
				required={active}
				icon={<IdentificationIcon />}
			/>
		</div>
	);
};

export default EidAppForm;
