const baseUrl = window.location.origin;
const redirectUrl = `${baseUrl}/callback`;
const signOutUrl = `${baseUrl}`;

const ddAppId = process.env.REACT_APP_DD_APP_ID ?? '8403b555-444d-43ed-80ca-6ee27c6e4685';
const ddClientToken = process.env.REACT_APP_DD_CLIENT_TOKEN ?? 'pub4cbf742c7941e8c78f011ce04e9c1ad4';

const apiUrl = process.env.REACT_APP_BASE_URL ?? 'http://localhost:8000';

export { redirectUrl, signOutUrl, ddAppId, ddClientToken, apiUrl };
