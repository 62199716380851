import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'src/context/AuthContext';
import { apiUrl } from 'src/constants';
import { DefaultTheme } from 'src/themes/DefaultTheme';
import { setColorTheme } from 'src/util/theme';

import AuthRouter from 'src/router/AuthRouter';
import { LoginPage } from 'src/pages';
import { Spinner, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { Settings } from 'src/components';

const App = () => {
	const [yPos, setYPos] = useState(window.scrollY);
	const [scrollUp, setScrollUp] = useState(true);

	const { isAuthenticated, user, isLoading, csrfToken, getSession, getCsrfToken, dispatch } = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const hostName = window.location.hostname;
	const partnerName = hostName === 'localhost' ? 'wise' : hostName.split('.')[1];

	let [searchParams, setSearchParams] = useSearchParams();

	const { isPending: sessionLoading } = useQuery({
		queryKey: ['session'],
		queryFn: getSession,
		enabled: !isAuthenticated,
		throwOnError: true,
	});

	const { isPending: csrfLoading } = useQuery({
		queryKey: ['csrfToken'],
		queryFn: getCsrfToken,
		enabled: !csrfToken,
		throwOnError: true,
	});

	const {
		data: partner,
		isPending: partnerLoading,
		isSuccess: partnerResolved,
	} = useQuery({
		queryKey: ['theme', partnerName],
		queryFn: async () => {
			const response = await fetch(`${apiUrl}/internal_api/portal/partner/${partnerName}/theme`, {
				credentials: 'include',
			});

			if (!response.ok) {
				throw new Error('Failed to fetch partner');
			}

			return response.json();
		},
		enabled: !!partnerName,
		staleTime: Infinity,
	});

	const loading = isLoading || sessionLoading || csrfLoading || partnerLoading;

	useEffect(() => {
		if (!isAuthenticated && !loading) {
			const error = searchParams.get('error');
			if (error) {
				navigate('/login?error=' + error);
			} else {
				navigate('/login');
			}
		}
	}, [isAuthenticated, loading]);

	useEffect(() => {
		if (partnerResolved) {
			dispatch({ type: 'SET_PARTNER', payload: partner });
			setColorTheme(partner.theme ?? DefaultTheme);
		}
	}, [partner, partnerResolved]);

	const handleScroll = useCallback(
		(event: Event) => {
			const window = event.currentTarget as Window;

			if (window.scrollY > yPos) {
				setScrollUp(false);
			} else if (window.scrollY < yPos) {
				setScrollUp(true);
			}
			setYPos(window.scrollY);
		},
		[yPos]
	);

	useEffect(() => {
		setYPos(window.scrollY);
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return (
		<>
			{loading ? (
				<div className="flex flex-col justify-center items-center h-screen gap-5">
					<Spinner color="gray" className="h-36 w-36 text-gray-500/50" />
					<Typography variant="h3" color="blue-gray" className="text-center">
						{t('loadingMessage')}
					</Typography>
				</div>
			) : (
				<>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/*" element={<AuthRouter />} />
					</Routes>
					{scrollUp && <Settings />}
				</>
			)}
		</>
	);
};

export default App;
