import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/AuthContext';
import { CustomerData } from 'src/types';

interface CustomerInfoProps {
	customerData: CustomerData;
}

export const CustomerInfo = (props: CustomerInfoProps) => {
	const { user, customer } = useAuth();

	const { t } = useTranslation();

	const { customerData } = props;

	return (
		<Card color="transparent" shadow={false}>
			<CardHeader
				color="transparent"
				shadow={false}
				floated={false}
				className="mx-0 mt-0 mb-4 flex items-center justify-between gap-4"
			>
				<Typography variant="h6" color="blue-gray">
					{t('customerInfo')}
				</Typography>
			</CardHeader>
			<CardBody className="p-0">
				{customerData.description && (
					<Typography variant="small" className="font-normal text-blue-gray-500">
						{customerData.description}
					</Typography>
				)}
			</CardBody>
		</Card>
	);
};

export default CustomerInfo;
