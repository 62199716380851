import {
	Card,
	CardHeader,
	Typography,
	Spinner,
	CardBody,
	Input,
	Tabs,
	TabsHeader,
	Tab,
	Select,
	Option,
} from '@material-tailwind/react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SubTable from 'src/components/SubTable';
import { EnvelopeResponse } from 'src/types/Envelope';
import { useAuth } from 'src/context/AuthContext';
import { apiUrl } from 'src/constants';
import { Pagination } from 'src/components/Pagination';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/solid';

const queryKeys = {
	paginated: (
		direction: string,
		customer: string,
		status: string,
		activePage: number,
		pageSize: number,
		identifier: string,
		search?: string
	) => ['envelopes', { direction, customer, status, activePage, pageSize, identifier, search }] as const,
	single: (id: string) => ['envelope', id] as const,
};

interface EnvelopesProps {
	direction: string;
}

export const Envelopes = (props: EnvelopesProps) => {
	const { direction } = props;

	const { customer, customerIdentifiers } = useAuth();
	const identifiers = [...customerIdentifiers, { customer_name: 'Allir', identifier: '' }];

	const [status, setStatus] = useState('all');
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(8);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [largeScreen, setLargeScreen] = useState(true);

	const [identifier, setIdentifier] = useState('');
	const [queryKey, setQueryKey] = useState(
		queryKeys.paginated(direction, customer, status, activePage, pageSize, identifier)
	);
	const { t, i18n } = useTranslation();

	const TABS = [
		{ label: t('all'), value: 'all' },
		{ label: t('new'), value: '0' },
		{ label: t('done'), value: '3' },
		{ label: t('error'), value: '4' },
		{ label: t('invalid'), value: '7' },
	];

	const TABLE_HEAD = [t('sender'), t('receiver'), t('invoice_id'), t('status'), t('type'), t('date'), ''];

	const fetchEnvelopes = async ({ queryKey }: { queryKey: any }): Promise<EnvelopeResponse> => {
		const [_, { direction, customer, status, activePage, pageSize, identifier, search }] = queryKey;

		let fetch_url = `${apiUrl}/internal_api/portal/envelope/?direction=${direction}&page_size=${pageSize}&page=${activePage}`;

		if (status !== 'all') {
			// Add status as a query parameter
			fetch_url += `&status=${status}`;
		}
		if (identifier) {
			// Add identifier as a query parameter
			fetch_url += `&identifier=${identifier}`;
		}
		if (search) {
			// Add search as a query parameter
			fetch_url += `&search=${search}`;
		}

		const response = await fetch(fetch_url, {
			credentials: 'include',
		});

		return response.json();
	};

	const { data, isPending, isPlaceholderData } = useQuery({
		queryKey: queryKey,
		queryFn: () => fetchEnvelopes({ queryKey }),
		enabled: !!customer,
	});

	const changeTab = (tab: string) => {
		setStatus(tab);
		setActivePage(1);
	};

	const handleChangeIdentifier = (value: string | undefined) => {
		setIdentifier(value ?? '');
	};

	const handleSearch = (search: string | undefined) => {
		setSearch(search);
		setActivePage(1);
		setQueryKey(queryKeys.paginated(direction, customer, status, activePage, pageSize, identifier, search));
	};

	const handlePageChange = (page: number) => {
		setActivePage(page);
	};

	useEffect(() => {
		setQueryKey(queryKeys.paginated(direction, customer, status, activePage, pageSize, identifier, search));
	}, [direction, customer, status, activePage, pageSize, identifier]);

	useEffect(() => {
		setActivePage(1);
	}, [direction]);

	const handleResize = () => {
		window.innerWidth >= 960 ? setLargeScreen(true) : setLargeScreen(false);
	};

	const expandTable = (expand: boolean) => {
		if (expand) {
			setPageSize(pageSize * 2);
		} else {
			setPageSize(8);
		}
		setActivePage(1);
	};

	useEffect(() => {
		if (window.innerWidth < 960) {
			setLargeScreen(false);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className="mb-8 flex flex-col gap-12">
			<Card>
				<CardHeader floated={false} shadow={false} className="rounded-sm">
					<div className="mb-8 flex items-center justify-between gap-8">
						<div>
							<Typography variant="h5" color="blue-gray">
								{t(direction === 'sent' ? 'outgoingDocuments' : 'incomingDocuments')}
							</Typography>
							<Typography color="gray" className="mt-1 font-normal">
								{t(direction === 'sent' ? 'outgoingHeader' : 'incomingHeader')}
							</Typography>
						</div>
					</div>
					<div className="flex flex-col items-center justify-between gap-4 md:flex-row">
						<Tabs className="w-full md:w-96 rounded-sm" value={status}>
							<TabsHeader
								className="rounded-sm grid grid-cols-2 sm:grid-cols-5 gap-1 overflow-hidden"
								indicatorProps={{
									className: 'bg-primary rounded-sm',
								}}
							>
								{TABS.map(({ label, value }) => (
									<Tab
										key={value}
										value={value}
										onClick={() => changeTab(value)}
										activeClassName="transition-colors ease-in-out text-white delay-150"
										className="z-0 px-3 whitespace-nowrap"
										disabled={isPending}
									>
										{label}
									</Tab>
								))}
							</TabsHeader>
						</Tabs>
						{identifiers.length > 1 && (
							<div className="w-72">
								<Select
									value={identifier}
									variant="standard"
									label="Identifier"
									disabled={isPending}
									className="w-72"
									animate={{
										mount: { x: -5, y: -5 },
										unmount: { x: 5, y: 25 },
									}}
									onChange={(value) => handleChangeIdentifier(value)}
								>
									{identifiers.map((customer) => (
										<Option key={customer.identifier} value={customer.identifier}>
											{customer.customer_name}
										</Option>
									))}
								</Select>
							</div>
						)}
						<Input
							crossOrigin=""
							variant="standard"
							type="text"
							label={t('search')}
							className="disabled:hidden"
							labelProps={{ className: 'peer-disabled:hidden' }}
							disabled={isPending}
							containerProps={{ className: 'w-full md:w-72' }}
							onBlur={(e) => handleSearch(e.target.value)}
							onKeyDown={(e) => e.key === 'Enter' && handleSearch(e.currentTarget.value)}
						/>
					</div>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0 pt-0 pb-2">
					{isPending ? (
						<div className="flex flex-col justify-center items-center gap-5 p-40">
							<Spinner className="h-36 w-36" color="gray" />
							<Typography variant="h3" color="blue-gray" className="text-center">
								{t('fetchingData')}
							</Typography>
						</div>
					) : (
						<table className="mt-4 w-full min-w-max table-auto text-left">
							<thead>
								<tr>
									{TABLE_HEAD.map((head) => (
										<th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
											<Typography
												variant="small"
												color="blue-gray"
												className="font-normal leading-none opacity-70"
											>
												{head}
											</Typography>
										</th>
									))}
								</tr>
							</thead>
							{data?.items && (
								<SubTable
									items={data.items}
									count={data.count}
									queryKey={queryKey}
									mutations={direction === 'received'}
								/>
							)}
						</table>
					)}
				</CardBody>
				{data && data.total_pages > 1 && (
					<div
						className="pb-1 mt-2 rounded-b-xl w-full flex items-center justify-center hover:shadow-2xl hover:bg-gradient-to-b hover:from-white hover:to-gray-100 cursor-pointer"
						onClick={() => expandTable(true)}
					>
						<ChevronDoubleDownIcon className="h-5 w-5 text-blue-gray-500" />
					</div>
				)}
				{data && data.count > 8 && data.total_pages === 1 && (
					<div
						className="pb-1 mt-2 rounded-b-xl w-full flex items-center justify-center hover:shadow-2xl hover:bg-gradient-to-b hover:from-white hover:to-gray-100 cursor-pointer"
						onClick={() => expandTable(false)}
					>
						<ChevronDoubleUpIcon className="h-5 w-5 text-blue-gray-500" />
					</div>
				)}
			</Card>
			{data && data.total_pages > 1 && (
				<Pagination
					disabled={isPending}
					activePage={activePage}
					pagination={data}
					size={largeScreen ? 'md' : 'sm'}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default Envelopes;
