import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'src/context/AuthContext';

import { ddAppId, ddClientToken, apiUrl } from 'src/constants/index';
import MaterialTailwindTheme from 'src/themes/MaterialTailwindTheme';
import 'src/index.css';
import App from 'src/App';
import 'src/i18n';
import HttpResponseError from 'src/errors/HttpResponseError';
import ErrorBoundary from 'src/context/ErrorBoundary';

datadogRum.init({
	applicationId: ddAppId,
	clientToken: ddClientToken,
	site: 'datadoghq.eu',
	service: 'courier-portal',
	env: process.env.NODE_ENV || 'local',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 100,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowedTracingUrls: [(url) => url.startsWith(apiUrl)],
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (error instanceof HttpResponseError) {
					if (error.status && error.status < 500) {
						return false;
					}
				}
				return failureCount < 4;
			},
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider value={MaterialTailwindTheme}>
				<QueryClientProvider client={queryClient}>
					<AuthProvider>
						<ErrorBoundary>
							<App />
						</ErrorBoundary>
					</AuthProvider>
				</QueryClientProvider>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
