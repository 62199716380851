export class HttpResponseError extends Error {
	public status?: number;
	public statusText?: string;

	constructor(message: string, response: Response) {
		super(message);
		this.status = response.status;
		this.statusText = response.statusText;
		this.name = 'HttpResponseError';
	}
}

export default HttpResponseError;
