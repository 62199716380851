// https://stackoverflow.com/a/30106551
// Used for decoding utf-8 encoded base64 string
export const b64DecodeUnicode = (str: string) => {
	return decodeURIComponent(
		Array.prototype.map
			.call(atob(str), function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
};
