import { createContext, useContext, useReducer, useMemo } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { DefaultTheme } from 'src/themes/DefaultTheme';
import { CustomerIdentifier, UserInfoResponse, CustomerData, SocialAccount, Theme, PartnerResponse } from 'src/types';
import { apiUrl } from 'src/constants';
import { HttpResponseError, NoCustomersError } from 'src/errors';

type LoginRequest = {
	partner_name: string;
	email?: string;
	password?: string;
	mobile_number?: string;
	national_id?: string;
};

interface State {
	customer: string;
	partner: PartnerResponse;
	user: UserInfoResponse;
	isAuthenticated: boolean;
	isLoading: boolean;
	customerIdentifiers: CustomerIdentifier[];
	csrfToken: string;

	signInMutation: UseMutationResult<Response, Error, LoginRequest, unknown>;
	signOutMutation: UseMutationResult<Response, Error, void, unknown>;
	getUserInfo: () => Promise<Response>;
	getSession: () => Promise<Response>;
	getCsrfToken: () => Promise<Response>;
	dispatch: React.Dispatch<any>;
}

const initialState: State = {
	customer: '',
	partner: {
		name: '',
		language_code: '',
		theme: DefaultTheme,
	} as PartnerResponse,
	user: {
		name: '',
		picture: '',
		email: '',
		customers: [] as CustomerData[],
		social_accounts: [] as SocialAccount[],
	} as UserInfoResponse,
	isAuthenticated: false,
	isLoading: false,
	csrfToken: '',
	customerIdentifiers: [] as CustomerIdentifier[],

	signInMutation: {} as UseMutationResult<Response, Error, LoginRequest, unknown>,
	signOutMutation: {} as UseMutationResult<Response, Error, void, unknown>,
	getUserInfo: async () => new Response(),
	getSession: async () => new Response(),
	getCsrfToken: async () => new Response(),
	dispatch: () => {},
};

const reducer = (state: State, action: any) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'SET_CSRF_TOKEN':
			return {
				...state,
				csrfToken: action.payload,
			};
		case 'SET_LOADING':
			return {
				...state,
				isLoading: action.payload,
			};
		case 'SET_CUSTOMER':
			return {
				...state,
				customer: action.payload,
			};
		case 'SET_CUSTOMER_IDENTIFIERS':
			return {
				...state,
				customerIdentifiers: action.payload,
			};
		case 'SET_PARTNER':
			return {
				...state,
				partner: action.payload,
			};
		case 'SET_IS_AUTHENTICATED':
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case 'SIGN_OUT':
			return {
				...state,
				user: {
					name: '',
					picture: '',
					email: '',
					customers: [] as CustomerData[],
					social_accounts: [] as SocialAccount[],
				} as UserInfoResponse,
				csrfToken: '',
				isAuthenticated: false,
			};
		default:
			return state;
	}
};

const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

	const signIn = async (body: LoginRequest) => {
		const response = await fetch(`${apiUrl}/internal_api/auth/login`, {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': state.csrfToken,
			},
			body: JSON.stringify(body),
		});

		if (response.status === 403) {
			throw new HttpResponseError('Error signing in', response);
		}

		if (!response.ok) {
			const error = await response.json();
			throw new HttpResponseError(error.message, response);
		}

		const csrfToken = response.headers.get('X-CSRFToken');
		dispatch({ type: 'SET_CSRF_TOKEN', payload: csrfToken });
		dispatch({ type: 'SET_IS_AUTHENTICATED', payload: true });

		return response;
	};

	const signInMutation = useMutation({
		mutationFn: signIn,
	});

	const signOut = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/logout`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'X-CSRFToken': state.csrfToken,
				'Content-Type': 'application/json',
			},
		});

		dispatch({ type: 'SIGN_OUT' });

		return response;
	};

	const signOutMutation = useMutation({
		mutationFn: signOut,
	});

	const getUserInfo = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/userinfo`, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			throw new HttpResponseError('Error fetching user info', response);
		}

		const userInfo = await response.json();

		if (userInfo.customers.length === 0) {
			throw new NoCustomersError('No customers found', response);
		}

		dispatch({ type: 'SET_CUSTOMER', payload: userInfo.customers[0].customer_number });
		dispatch({ type: 'SET_USER', payload: userInfo });

		return response;
	};

	const getSession = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/session`, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			throw new HttpResponseError('Error fetching session', response);
		}

		const data = await response.json();
		dispatch({ type: 'SET_IS_AUTHENTICATED', payload: data.isAuthenticated });

		return response;
	};

	const getCsrfToken = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/csrf`, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			throw new HttpResponseError('Error fetching csrf token', response);
		}

		const csrfToken = response.headers.get('X-CSRFToken');
		dispatch({ type: 'SET_CSRF_TOKEN', payload: csrfToken });

		return response;
	};

	return (
		<AuthContext.Provider
			value={{ ...value, signInMutation, signOutMutation, getUserInfo, getSession, getCsrfToken, dispatch }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const authContext = useContext(AuthContext);

	if (!authContext) {
		throw new Error('useAuth must be used within a AuthContextProvider');
	}

	return authContext;
};

export default AuthProvider;
