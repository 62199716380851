import { CardHeader, CardBody, Typography, Spinner } from '@material-tailwind/react';
import CustomCard from 'src/components/CustomCard';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/AuthContext';
import { apiUrl } from 'src/constants';

export const HomePage = () => {
	const { customer } = useAuth();
	const { t } = useTranslation();

	const { data, isPending } = useQuery({
		queryKey: ['dashboard', customer],
		queryFn: async () => {
			const today = new Date();
			const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));

			const response = await fetch(
				`${apiUrl}/internal_api/portal/customer/dashboard?start_date=${thirtyDaysAgo.toISOString()}`,
				{
					credentials: 'include',
				}
			);

			if (response.ok) {
				return response.json();
			}
		},
		enabled: !!customer,
	});

	return (
		<div className="flex flex-col items-center md:flex-row md:gap-x-5 gap-y-5">
			<CustomCard color="primary" variant="gradient" className="w-10/12 md:w-4/12 h-64 p-8 rounded-md">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-sm border-b border-white/10 pb-4 text-center"
				>
					<Typography color="white" variant="h6">
						{t('sentPast30days')}
					</Typography>
				</CardHeader>
				{isPending ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.sent_envelopes}</Typography>
					</CardBody>
				)}
			</CustomCard>
			<CustomCard color="primary" variant="gradient" className="w-10/12 md:w-4/12 h-64 p-8 rounded-md">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-sm border-b border-white/10 pb-4 text-center"
				>
					<Typography color="white" variant="h6">
						{t('receivedPast30days')}
					</Typography>
				</CardHeader>
				{isPending ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.received_envelopes}</Typography>
					</CardBody>
				)}
			</CustomCard>
			<CustomCard color="primary" variant="gradient" className="w-10/12 md:w-4/12 h-64 p-8 rounded-md">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-sm border-b border-white/10 pb-4 text-center"
				>
					<Typography color="white" variant="h6">
						{t('viewedDocuments')}
					</Typography>
				</CardHeader>
				{isPending ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.envelope_views}</Typography>
					</CardBody>
				)}
			</CustomCard>
		</div>
	);
};

export default HomePage;
