import { Typography, Input, Button } from '@material-tailwind/react';
import '../index.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useAuth } from 'src/context/AuthContext';

export const EmailForm = () => {
	const { t } = useTranslation();
	const { signInMutation } = useAuth();

	return (
		<div className="w-full pb-4">
			<Typography variant="h3" color="black" className="text-center font-bold mb-8">
				{t('emailSignInLabel')}
			</Typography>
			<hr className="mb-4 border-blue-gray-50" />
			<Typography color="gray" className="text-center mb-12">
				{t('emailSignInDescription')}
			</Typography>
			<div className="mb-4">
				<Input
					id="email"
					variant="standard"
					color="gray"
					size="lg"
					type="email"
					name="email"
					label={t('email')}
					crossOrigin={''}
					required
					icon={<EnvelopeIcon />}
				/>
			</div>
			<div className="mb-4">
				<Input
					id="password"
					variant="standard"
					color="gray"
					size="lg"
					type="password"
					name="password"
					label={t('password')}
					crossOrigin={''}
					required
				/>
			</div>
		</div>
	);
};

export default EmailForm;
