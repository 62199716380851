import { HomeIcon, DocumentArrowUpIcon, DocumentArrowDownIcon, UserIcon } from '@heroicons/react/24/solid';
import { HomePage, Envelopes, ProfilePage } from 'src/pages';

const icon = {
	className: 'w-5 h-5 text-inherit',
};

export const routes = {
	home: {
		icon: <HomeIcon {...icon} />,
		name: '',
		path: '/',
		element: <HomePage />,
	},
	profile: {
		icon: <UserIcon {...icon} />,
		name: 'profile',
		path: '/profile',
		element: <ProfilePage />,
	},
	pages: [
		{
			icon: <DocumentArrowDownIcon {...icon} />,
			name: 'incomingDocuments',
			path: '/incoming-documents',
			element: <Envelopes direction="received" />,
		},
		{
			icon: <DocumentArrowUpIcon {...icon} />,
			name: 'outgoingDocuments',
			path: '/outgoing-documents',
			element: <Envelopes direction="sent" />,
		},
	],
};

export default routes;
