import { Theme } from 'src/types';

const extractRgbValue = (rgbColor: string) => {
	return rgbColor.replace(/[^\d ]/g, '');
};

const setColorTheme = (theme: Theme) => {
	Object.keys(theme.primary).forEach((key) => {
		const rgbValue = extractRgbValue(theme.primary[key]);
		const colorWeight = key.replace(/[^\d]/g, '');
		if (key === 'DEFAULT') {
			document.documentElement.style.setProperty(`--color-primary`, rgbValue);
		} else {
			document.documentElement.style.setProperty(`--color-primary-${colorWeight}`, rgbValue);
		}
	});

	Object.keys(theme.secondary).forEach((key) => {
		const rgbValue = extractRgbValue(theme.secondary[key]);
		const colorWeight = key.replace(/[^\d]/g, '');
		if (key === 'DEFAULT') {
			document.documentElement.style.setProperty(`--color-secondary`, rgbValue);
		} else {
			document.documentElement.style.setProperty(`--color-secondary-${colorWeight}`, rgbValue);
		}
	});
};

export { setColorTheme };
