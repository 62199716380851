import i18n from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { NoCustomersError } from 'src/errors';
import HttpResponseError from 'src/errors/HttpResponseError';
import ErrorPage from 'src/pages/ErrorPage';

type Props = {
	children: React.ReactNode;
	t: any;
};

type ErrorState = {
	hasError: boolean;
	error: Error | null;
};

// This error boundary is used to catch errors that are thrown in the render phase of the component lifecycle.
// It will catch React query errors where throwOnError is set to true. It will also catch errors thrown in the
// render phase of a component.
export class ErrorBoundary extends React.Component<Props, ErrorState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			hasError: false,
			error: null,
		};
	}

	static getDerivedStateFromError(error: Error) {
		return {
			hasError: true,
			error: error,
		};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.setState({
			hasError: true,
			error: error,
		});
	}

	render() {
		const { error } = this.state;
		const { t } = this.props;

		if (error instanceof HttpResponseError) {
			return (
				<ErrorPage title={error.status?.toString()} description={error.statusText} message={error.message} />
			);
		}

		if (error instanceof NoCustomersError) {
			return (
				<ErrorPage
					title={t('noCustomersTitle')}
					description={t('noCustomersDescription')}
					message={t('noCustomersMessage')}
				/>
			);
		}

		if (error instanceof Error) {
			return <ErrorPage />;
		}

		return <>{this.props.children}</>;
	}
}

export default withTranslation()(ErrorBoundary);
